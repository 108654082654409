//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-972:_2504,_8364,_9240,_8668,_8684,_8428,_7551,_3724;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-972')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-972', "_2504,_8364,_9240,_8668,_8684,_8428,_7551,_3724");
        }
      }catch (ex) {
        console.error(ex);
      }