// default Add to Cart button handler, taken from theme "assets/product-form.js"
// this script in theme works on customElements and run when element render in DOM
// as we use React, not all DOM elements inside product-form are rendered
// so we need to use this script after all our elements are fully rendered

function updateCartIcon(response) {
  const cartIconBubble = document.getElementById('cart-icon-bubble');
  if (cartIconBubble) {
    cartIconBubble.innerHTML = response.sections['cart-icon-bubble'];
  }
}

function setErrorMsgState(error = false) {
  this.errorMsg = this.errorMsg || this.querySelector('.js-form-error');
  if (!this.errorMsg) {
    return;
  }

  this.errorMsg.hidden = !error;
  if (error) {
    this.errorMsg.textContent = error;
  }
}

export async function handleSubmit(evt) {
  this.form = this.querySelector('.js-product-form');
  this.form.querySelector('[name="id"]').disabled = false;
  this.cartDrawer = document.querySelector('cart-drawer');
  this.submitBtn = this.querySelector('[name="add"]');

  if (evt.target.id === 'product-signup_form') {
    return;
  }

  evt.preventDefault();

  if (this.submitBtn.getAttribute('aria-disabled') === 'true') {
    return;
  }

  if (window.theme.settings.vibrateOnATC && window.navigator.vibrate) {
    window.navigator.vibrate(100);
  }

  this.errorMsg = null;
  setErrorMsgState.bind(this);

  // Disable "Add to Cart" button until submission is complete.
  this.submitBtn.setAttribute('aria-disabled', 'true');
  this.submitBtn.classList.add('is-loading');

  const formData = new FormData(this.form);
  let sections = 'cart-icon-bubble';
  if (this.cartDrawer) {
    sections += `,${this.cartDrawer.closest('.shopify-section').id.replace('shopify-section-', '')}`;
  }

  formData.append('sections_url', window.location.pathname);
  formData.append('sections', sections);

  const fetchRequestOpts = {
    method: 'POST',
    headers: {
      'Accept': 'application/javascript',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  };

  try {
    const oldCartResponse = await fetch(`${window.theme.routes.cart}.js`);
    if (!oldCartResponse.ok) {
      throw new Error(oldCartResponse.status);
    }
    const oldCartData = await oldCartResponse.json();

    const response = await fetch(window.theme.routes.cartAdd, fetchRequestOpts);
    const data = await response.json();
    const error = typeof data.description === 'string' ? data.description : data.message;

    if (data.status) {
      setErrorMsgState(error);
    }

    if (!response.ok) {
      throw new Error(response.status);
    }

    if (window.theme.settings.afterAtc === 'page') {
      // Allow the tick animation to complete
      setTimeout(() => {
        window.location.href = window.theme.routes.cart;
      }, 300);
    } else {
      // Update cart icon count.
      // window.ProductForm.updateCartIcon(data);
      updateCartIcon(data);

      // If item was added from Quick Add drawer, show "Added to cart" message.
      const quickAddDrawer = this.closest('quick-add-drawer');
      if (quickAddDrawer) {
        quickAddDrawer.addedToCart();
      }

      setTimeout(() => {
        // Update cart drawer contents.
        if (this.cartDrawer) {
          this.cartDrawer.renderContents(
            data,
            !quickAddDrawer && window.theme.settings.afterAtc === 'drawer',
          );
        } else if (window.location.pathname === window.theme.routes.cart) {
          const cartItems = document.querySelector('cart-items');
          if (cartItems) {
            if (cartItems.dataset.empty === 'true') {
              window.location.reload();
            } else {
              cartItems.refreshCart();
            }
          }
        }
      }, 700);
    }

    const newCartResponse = await fetch(`${window.theme.routes.cart}.js`);
    if (!newCartResponse.ok) {
      throw new Error(newCartResponse.status);
    }
    const newCartData = await newCartResponse.json();
    const [itemInOldCart] = oldCartData.items.filter((item) => item.variant_id === data.variant_id);

    // Check if product was already in the cart
    if (itemInOldCart) {
      this.dispatchEvent(
        new CustomEvent('on:line-item:change', {
          bubbles: true,
          detail: {
            cart: newCartData,
            variantId: data.variant_id,
            oldQuantity: itemInOldCart.quantity,
            newQuantity: itemInOldCart.quantity === data.quantity ? itemInOldCart.quantity : data.quantity,
          },
        }),
      );
    } else {
      this.dispatchEvent(
        new CustomEvent('on:cart:add', {
          bubbles: true,
          detail: {
            cart: newCartData,
            variantId: data.variant_id,
          },
        }),
      );
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
    this.dispatchEvent(
      new CustomEvent('on:cart:error', {
        bubbles: true,
        detail: {
          error: this.errorMsg.textContent,
        },
      }),
    );
  } finally {
    // Re-enable 'Add to Cart' button.
    this.submitBtn.classList.add('is-success');
    this.submitBtn.removeAttribute('aria-disabled');
    setTimeout(() => {
      this.submitBtn.classList.remove('is-loading');
      this.submitBtn.classList.remove('is-success');
    }, 1400);
  }
}
